export enum TimeclockEvents {
  CREATED = "CREATED"
}

export enum TimeEventType {
  CHECK_IN = "CHECK_IN",
  BREAK_TIME_IN = "BREAK_TIME_IN",
  BREAK_TIME_OUT = "BREAK_TIME_OUT",
  CHECK_OUT = "CHECK_OUT"
}
