import { TimeEventType } from "@/components/timeclock/Timeclock.enum";

export interface Timeclock {
  id: number | null;
  user: any;
  employee: string;
  registered_date: string;
  check_in: string;
  check_out: string | null;
  break_for: string | null;
  lunch_time_start: string;
  lunch_time_end: string;
  status: string;
  timeclock_events: TimeclockEvent[];
  parsedEvents: object[] | string;
  work_for?: string | null;
}

export interface TimeclockEvent {
  id?: number | null;
  temporalId?: number | null;
  event: string | TimeEventType;
  registered_at: string | Date;
}

export const timeclockEventDefault = {
  id: null,
  event: "",
  registered_at: ""
};

export const timeclockDefault = {
  id: null,
  user: null,
  employee: "",
  registered_date: "",
  check_in: "",
  check_out: "",
  break_for: "",
  lunch_time_start: "",
  lunch_time_end: "",
  status: "",
  timeclock_events: [],
  parsedEvents: []
};

export interface TimeclockChangeRequst {
  timeclock_id: number;
  note: string;
  timeclockChangeRequestEvents: TimeclockChangeRequestEvent[];
}

export interface TimeclockChangeRequestEvent {
  event: TimeEventType;
  registered_at: string;
}
