import { render, staticRenderFns } from "./TimeclockEvent.template.vue?vue&type=template&id=60401ddd&scoped=true&"
var script = {}
import style0 from "./TimeclockEvent.template.vue?vue&type=style&index=0&id=60401ddd&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60401ddd",
  null
  
)

export default component.exports