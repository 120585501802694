import {
  Timeclock,
  TimeclockEvent,
  timeclockEventDefault
} from "@/interfaces/timeclock";
import { EventBus } from "@/internal";
import { timeclockService } from "@/services/timeclock.service";
import { Callback } from "@/types/types";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import addMinutes from "date-fns/addMinutes";
import differenceInMinutes from "date-fns/differenceInMinutes";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import isAfter from "date-fns/isAfter";
import isSameMinute from "date-fns/isSameMinute";
import isWithinInterval from "date-fns/isWithinInterval";
import subMinutes from "date-fns/subMinutes";
import { HelixDatePickerOptions } from "helix-vue-components";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import findLast from "lodash/findLast";
import head from "lodash/head";
import indexOf from "lodash/indexOf";
import isArray from "lodash/isArray";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { TimeEventType } from "../../Timeclock.enum";
import { TimeclockEvents } from "../../Timeclock.events";
import Template from "./TimeclockEvent.template.vue";

interface BreakdownEvents {
  [TimeEventType.CHECK_IN]: undefined | Date;
  [TimeEventType.BREAK_TIME_IN]: undefined | Date;
  [TimeEventType.BREAK_TIME_OUT]: undefined | Date;
  [TimeEventType.CHECK_OUT]: undefined | Date;
}
const MAX_TIME = "23:59:00";
const addOneMin = (value: string) => {
  return fnsFormatDate(
    new Date(addMinutes(new Date(value), 1)),
    FNS_DATE_FORMATS.LOCAL_TIME
  );
};
const subtractOneMin = (value: string) => {
  return fnsFormatDate(
    new Date(subMinutes(new Date(value), 1)),
    FNS_DATE_FORMATS.LOCAL_TIME
  );
};

interface AccInterface {
  breakIns: string[];
  breakOuts: string[];
}
@Component({
  mixins: [Template]
})
export default class TimeclockEventModalComponent extends Vue {
  @Prop({ default: null })
  public event!: TimeclockEvent;
  public eventEdition: TimeclockEvent = cloneDeep(timeclockEventDefault);
  public eventIndex: number = -1;
  @Prop({ default: [] })
  public allEvents!: TimeclockEvent[];
  @Prop({ required: true })
  public model!: Timeclock;
  @Prop({ required: true })
  public onCreate!: (event: TimeclockEvent) => void;
  public checkedOut: boolean = false;
  public datetime: string | Date = new Date();
  public alreadyOnTable: boolean = false;
  public pickerConfig: Partial<HelixDatePickerOptions> = {};
  public items: Array<{ name: string; value: string }> = [];

  @Action("checkInTimeclock", { namespace: "TimeclockModule" })
  public checkInTimeclock!: Callback;

  // All function for validating date input
  private disabledDates = {
    [TimeEventType.CHECK_IN]: this.disabledCheckIn.bind(this),
    [TimeEventType.BREAK_TIME_IN]: this.disabledBreakIn.bind(this),
    [TimeEventType.BREAK_TIME_OUT]: this.disabledBreakOut.bind(this),
    [TimeEventType.CHECK_OUT]: this.disabledCheckOut.bind(this)
  };

  private breakdownEvents: BreakdownEvents = {
    [TimeEventType.CHECK_IN]: undefined,
    [TimeEventType.BREAK_TIME_IN]: undefined,
    [TimeEventType.BREAK_TIME_OUT]: undefined,
    [TimeEventType.CHECK_OUT]: undefined
  };

  public cancel() {
    this.eventEdition = { ...timeclockEventDefault };
    this.$emit("closeModal");
    this.$emit("reject");
  }
  // Makes sure the dates are in the correct timezone
  public get parsedAllEvents(): TimeclockEvent[] {
    return (
      this.allEvents &&
      this.allEvents.map(event => ({
        ...event,
        registered_at: new Date(event.registered_at)
      }))
    );
  }

  public pickerOptions(): Partial<HelixDatePickerOptions> {
    const disabledDate = this.disabledDates[
      this.eventEdition.event as TimeEventType
    ];

    const selectableRange =
      (!this.event &&
        this.eventEdition.event === TimeEventType.BREAK_TIME_IN &&
        this.optionsBreakIn()) ||
      this.timeOptions();

    const currentRange = isArray(selectableRange)
      ? selectableRange[0]
      : selectableRange;
    if (this.parsedAllEvents.length && currentRange) {
      this.updateDateTime(currentRange.split(" - ")[0]);
    }

    return {
      placeholder: this.$t("select_time").toString(),
      disabled: !this.eventEdition.event,
      "picker-options": {
        selectableRange,
        disabledDate
      }
    };
  }

  public onSelection() {
    this.pickerConfig = this.pickerOptions();
  }

  private get optionCheckOut() {
    return (
      (this.eventEdition.event === TimeEventType.CHECK_OUT &&
        this.parsedAllEvents[this.parsedAllEvents.length - 2]) ||
      null
    );
  }
  private get optionCheckIn() {
    return (
      (this.eventEdition.event === TimeEventType.CHECK_IN &&
        this.parsedAllEvents[1]) ||
      null
    );
  }

  private get findIndexEventEdit() {
    const context = this;
    return findIndex(this.parsedAllEvents, e => {
      return (
        (e.id && e.id === context.eventEdition.id) ||
        (!!e.temporalId && e.temporalId === context.eventEdition.temporalId)
      );
    });
  }

  public async addCheckIn() {
    const data = {
      user_id: this.model.user.id,
      registered_date: fnsFormatDate(new Date(this.datetime)),
      check_in: this.datetime
    };

    const response = await timeclockService.post(data);
    if (!response) {
      return;
    }
    this.onCreate(response as TimeclockEvent);
    this.$router.replace(
      {
        name: "time-clock-edit",
        params: { id: response.id }
      },
      () => {
        EventBus.$emit(`tc${TimeclockEvents.CREATED}`);
      }
    );
  }

  public saveMoment() {
    if (this.alreadyOnTable) {
      this.$emit("resolve", { type: "edit", data: this.finalEvent() });
      return;
    }
    if (this.eventEdition.event === "CHECK_IN") {
      this.addCheckIn();
      this.$emit("reject");
    } else {
      this.setBreakdownEvents();
      this.$emit("resolve", { type: "new", data: this.finalEvent() });
    }
  }

  public mounted() {
    if (this.event) {
      this.eventEdition = { ...this.event };
      this.alreadyOnTable = true;
    }

    this.setEventTypes();
    this.setBreakdownEvents();
    this.pickerConfig = this.pickerOptions();
  }

  /**
   * Datepicker is assigned the date corresponding to the event to be modified,
   * or the corresponding date for a new event.
   */
  private updateDateTime(currentRange: string) {
    const date = new Date(this.parsedAllEvents[0].registered_at);
    const time =
      this.event && this.eventEdition.registered_at
        ? fnsFormatDate(
            new Date(this.eventEdition.registered_at),
            FNS_DATE_FORMATS.LOCAL_TIME
          ).split(":")
        : currentRange.split(":");

    this.datetime = new Date(
      getYear(date),
      getMonth(date),
      getDate(date),
      +time[0],
      +time[1],
      +time[2]
    );
  }

  private optionsBreakIn() {
    return this.parsedAllEvents.length > 1
      ? chunk(this.parsedAllEvents, 2)
          .filter(e => {
            const time = differenceInMinutes(
              new Date(e[1].registered_at),
              new Date(e[0].registered_at)
            );

            return time > 10;
          })
          .map(e => {
            return `${e[0] &&
              addOneMin(String(e[0].registered_at))} - ${(e[1] &&
              subtractOneMin(String(e[1].registered_at))) ||
              MAX_TIME}`;
          })
      : null;
  }

  private timeOptions() {
    const minTime = "00:00:00";
    const breakTimeInOpen = this.findBreakStatusOpen(this.parsedAllEvents);
    const allEvents = this.parsedAllEvents;
    const indexEvent = this.findIndexEventEdit || 1;
    const topRange =
      this.optionCheckOut ||
      (breakTimeInOpen && breakTimeInOpen.top) ||
      ((indexEvent - 1 >= 0 && allEvents[indexEvent - 1]) || null);
    const bottomRange =
      this.optionCheckIn ||
      (breakTimeInOpen && breakTimeInOpen.bottom) ||
      ((indexEvent + 1 <= allEvents.length - 1 && allEvents[indexEvent + 1]) ||
        null);
    const topLimit =
      (topRange &&
        this.eventEdition.event !== TimeEventType.CHECK_IN &&
        addOneMin(String(topRange.registered_at))) ||
      minTime;
    const bottomLimit =
      (bottomRange &&
        this.eventEdition.event !== TimeEventType.CHECK_OUT &&
        subtractOneMin(String(bottomRange.registered_at))) ||
      MAX_TIME;
    return `${topLimit} - ${bottomLimit}`;
  }

  private findBreakStatusOpen(eventsForTheDay: TimeclockEvent[]) {
    if (
      this.event &&
      this.eventEdition.event !== TimeEventType.BREAK_TIME_OUT
    ) {
      return;
    }
    const breakInDesface = eventsForTheDay.filter(event => {
      const indexNextEvent = indexOf(eventsForTheDay, event) + 1;
      return (
        event.event === TimeEventType.BREAK_TIME_IN &&
        (indexNextEvent === eventsForTheDay.length ||
          (eventsForTheDay[indexNextEvent] &&
            eventsForTheDay[indexNextEvent].event !==
              TimeEventType.BREAK_TIME_OUT))
      );
    });
    return (
      (breakInDesface.length && {
        top: eventsForTheDay[indexOf(eventsForTheDay, breakInDesface[0])],
        bottom:
          eventsForTheDay[indexOf(eventsForTheDay, breakInDesface[0]) + 1] ||
          MAX_TIME
      }) ||
      null
    );
  }

  private setBreaks() {
    return this.parsedAllEvents.reduce(
      (acc: AccInterface, event: TimeclockEvent) => {
        if (event.event === "BREAK_TIME_IN") {
          acc.breakIns.push(event.event);
        }
        if (event.event === "BREAK_TIME_OUT") {
          acc.breakOuts.push(event.event);
        }
        return acc;
      },
      { breakIns: [], breakOuts: [] }
    );
  }

  private setEventTypes() {
    // the current event is on the table
    if (this.alreadyOnTable) {
      // only available type when editing is the same event
      this.items = [
        {
          name: this.$t(this.eventEdition.event).toString(),
          value: this.eventEdition.event
        }
      ];
    } else {
      const events = this.parsedAllEvents.map(element => element.event);
      const breaks: AccInterface = this.setBreaks();

      // if no events only can add check_in
      if (!events.length) {
        this.items = [
          {
            name: this.$t(TimeEventType.CHECK_IN).toString(),
            value: TimeEventType.CHECK_IN
          }
        ];
        this.eventEdition.event = "CHECK_IN";
        return;
      } else {
        // can a a break_in
        if (
          // if to each break in there is a break out
          breaks.breakIns.length === breaks.breakOuts.length ||
          // there is a break out missing its break in
          breaks.breakIns.length < breaks.breakOuts.length
        ) {
          this.items.push({
            name: this.$t(TimeEventType.BREAK_TIME_IN).toString(),
            value: TimeEventType.BREAK_TIME_IN
          });
          // if a break in is missing its break out
          // and only vailable choice is break out
        } else if (breaks.breakIns.length > breaks.breakOuts.length) {
          this.items.push({
            name: this.$t(TimeEventType.BREAK_TIME_OUT).toString(),
            value: TimeEventType.BREAK_TIME_OUT
          });
          this.eventEdition.event = "BREAK_TIME_OUT";
          return;
        }
        // can add a check out
        if (
          // if there isnt already one
          !events.includes(TimeEventType.CHECK_OUT) &&
          // to each break in there is a break out
          breaks.breakIns.length === breaks.breakOuts.length
        ) {
          this.items.push({
            name: this.$t(TimeEventType.CHECK_OUT).toString(),
            value: TimeEventType.CHECK_OUT
          });
        }
      }
    }
  }

  private setBreakdownEvents() {
    const first = head(this.parsedAllEvents);
    const checkIn = first ? new Date(first.registered_at) : undefined;

    const lastBreakIn = findLast(this.parsedAllEvents, {
      event: TimeEventType.BREAK_TIME_IN
    });

    const breakIn = lastBreakIn
      ? new Date(lastBreakIn.registered_at)
      : undefined;

    const lastBreakOut = findLast(this.parsedAllEvents, {
      event: TimeEventType.BREAK_TIME_OUT
    });

    const breakOut = lastBreakOut
      ? new Date(lastBreakOut.registered_at)
      : undefined;

    const lastCheckOut = find(this.parsedAllEvents, {
      event: TimeEventType.CHECK_OUT
    });

    const checkOut = lastCheckOut
      ? new Date(lastCheckOut.registered_at)
      : undefined;

    this.breakdownEvents = {
      [TimeEventType.CHECK_IN]: checkIn,
      [TimeEventType.BREAK_TIME_IN]: breakIn,
      [TimeEventType.BREAK_TIME_OUT]: breakOut,
      [TimeEventType.CHECK_OUT]: checkOut
    };
  }

  private finalEvent() {
    const datetime = new Date(this.datetime).toISOString();
    return {
      ...this.eventEdition,
      registered_at: datetime,
      [this.eventEdition.event.toLowerCase()]: datetime
    };
  }

  private disabledCheckIn(date: Date) {
    const now = new Date().setHours(0, 0, 0, 0);
    const {
      [TimeEventType.BREAK_TIME_IN]: breakIn,
      [TimeEventType.CHECK_OUT]: checkOut
    } = this.breakdownEvents;
    return isAfter(date, breakIn || checkOut || now);
  }

  private disabledBreakIn(date: Date) {
    const now = new Date().setHours(0, 0, 0, 0);
    const {
      [TimeEventType.CHECK_IN]: checkIn,
      [TimeEventType.BREAK_TIME_OUT]: breakOut,
      [TimeEventType.CHECK_OUT]: checkOut
    } = this.breakdownEvents;

    const breakOutTime = breakOut
      ? isSameMinute(date, breakOut) && breakOut.setHours(0, 0, 0, 0)
      : false;

    return !isWithinInterval(date, {
      start: breakOutTime || (checkIn as Date).setHours(0, 0, 0, 0),
      end: (checkOut ? checkOut.setHours(0, 0, 0, 0) : false) || now
    });
  }

  private disabledBreakOut(date: Date) {
    const now = new Date().setHours(0, 0, 0, 0);
    const {
      [TimeEventType.CHECK_IN]: checkIn,
      [TimeEventType.BREAK_TIME_IN]: breakIn,
      [TimeEventType.CHECK_OUT]: checkOut
    } = this.breakdownEvents;

    const start = breakIn || checkIn;
    return !isWithinInterval(date, {
      start: (start as Date).setHours(0, 0, 0, 0),
      end: (checkOut ? checkOut.setHours(0, 0, 0, 0) : false) || now
    });
  }

  private disabledCheckOut(date: Date) {
    const now = new Date().setHours(0, 0, 0, 0);
    const {
      [TimeEventType.CHECK_IN]: checkIn,
      [TimeEventType.BREAK_TIME_IN]: breakIn,
      [TimeEventType.BREAK_TIME_OUT]: breakOut
    } = this.breakdownEvents;

    const start = breakOut || breakIn || checkIn;
    return !isWithinInterval(date, {
      start: (start as Date).setHours(0, 0, 0, 0),
      end: now
    });
  }
}
